import { Submission } from '@wix/forms-ui/dist/types/types';
import { Form } from '../../types/ambassador/bookings/ambassador-services-catalog';
import { ContactDetails } from '../../types/ambassador/bookings/ambassador-bookings-v2-booking';

const getContactDetailsFormFieldIds = (form: Form) => ({
  nameFieldId: form.firstName?.fieldId!,
  lastNameFieldId: form.lastName?.fieldId!,
  emailFieldId: form.email?.fieldId!,
  phoneFieldId: form.phone?.fieldId!,
});

export const getContactDetailsWithFieldIds = ({
  form,
  contactDetails,
}: {
  form?: Form;
  contactDetails?: ContactDetails;
}): Submission => {
  const { nameFieldId, lastNameFieldId, emailFieldId, phoneFieldId } =
    getContactDetailsFormFieldIds(form!);

  const name =
    contactDetails?.firstName! +
    (!lastNameFieldId && contactDetails?.lastName
      ? ` ${contactDetails?.lastName}`
      : '');

  return {
    [nameFieldId]: name,
    ...(lastNameFieldId ? { [lastNameFieldId]: contactDetails?.lastName } : {}),
    [emailFieldId]: contactDetails?.email,
    [phoneFieldId]: contactDetails?.phone,
  };
};
